<template>
  <v-footer class="px-0 d-flex flex-column white">
    <v-container>
      <v-divider class="grey w-100 mb-4" />
      <v-row no-gutters>
        <v-col cols="12">
          <v-row
            no-gutters
            v-if="links && $vuetify.breakpoint.mdAndUp"
            justify="start"
            @click="clicked"
          >
            <!-- custom layout for column with "socials" CSS class, showing button for app download -->
            <!-- "socials" column not rendered on mobile app -->
            <v-col
              v-for="(link, index) in links"
              :key="index"
              cols="12"
              sm="6"
              md="3"
              lg="2"
              class="d-flex flex-column justify-start align-center align-md-start secondary--text pr-md-2"
            >
              <h4 class="text-center font-weight-bold text-sm-left mt-4">
                {{ link.name }}
              </h4>
              <a
                :id="`footer-menu-${link.templateNavItemId}`"
                v-for="child in link.templateNavItems"
                :key="child.templateNavItemId"
                :href="child.itemUrl"
                class="default--text text-decoration-none pa-1"
                :class="child.classStyle"
                :target="child.target || '_self'"
                @click="clickLink"
              >
                {{ child.name }}
              </a>
            </v-col>
          </v-row>
          <div v-else-if="links && $vuetify.breakpoint.smAndDown">
            <v-expansion-panels
              accordion
              flat
              tile
              :focusable="selectedTab"
              class="px-4"
            >
              <v-expansion-panel v-for="(link, index) in links" :key="index">
                <v-expansion-panel-header>
                  <template v-slot:actions>
                    <v-icon>$chevronDown</v-icon>
                  </template>
                  <span
                    class="secondary--text font-weight-bold text-uppercase"
                    >{{ link.name }}</span
                  >
                </v-expansion-panel-header>
                <v-expansion-panel-content class="mt-2">
                  <a
                    v-for="child in link.templateNavItems"
                    :key="child.templateNavItemId"
                    :href="child.itemUrl"
                    class="d-block default--text text-decoration-none pa-1"
                    :class="[child.classStyle, { 'no-evt': index == 2 }]"
                    :target="child.target"
                    @click="clickLink"
                  >
                    <span :class="child.classes">{{ child.name }}</span>
                  </a>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-container :fluid="$vuetify.breakpoint.xs">
      <v-divider class="grey w-100" />
      <v-row class="py-3">
        <v-col
          cols="12"
          sm="6"
          class="d-flex"
          :class="$vuetify.breakpoint.xs ? 'justify-center' : 'justify-start'"
        >
          <a
            href="https://apps.apple.com/it/app/al%C3%AC/id1440578549"
            target="_blank"
          >
            <img
              class="d-block"
              src="/img_layout/footer/app-store.png"
              alt="Immagine app store"
            />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=it.digitelematica.ebsn.sidal"
            target="_blank"
          >
            <img
              class="d-block ml-3"
              src="/img_layout/footer/google-play.png"
              alt="Immagine google play"
            />
          </a>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          class="d-flex align-center pa-2 logo-gap"
          :class="$vuetify.breakpoint.xs ? 'justify-center' : 'justify-end'"
        >
          <div
            v-for="(item, idx) in socialItems"
            :key="idx"
            class="d-flex align-center"
          >
            <a :href="item.url" target="_blank" class="text-decoration-none"
              ><img
                class="d-block"
                :src="`/img_layout/footer/${item.icon}.svg`"
                :alt="`Immagine ${item.icon}`"
              />
            </a>
          </div>
        </v-col>
      </v-row>
      <v-layout
        class="align-center d-inline-flex py-4 primary white--text rounded-sm w-100"
        :column="$vuetify.breakpoint.xs"
      >
        <div
          class="text-caption mx-3 mx-sm-10"
          v-html="$t('footer.copyright')"
        />

        <v-spacer />

        <i18n path="footer.version" tag="span" class="white--text text-caption">
          {{ version }}
        </i18n>
      </v-layout>
    </v-container>
  </v-footer>
</template>
<style scoped lang="scss">
.container {
  background-color: $white;
}
.v-expansion-panel-header {
  border-bottom: 1px solid $border-color !important;
}
.logo-gap {
  column-gap: 7px;
}
</style>

<script>
import clickHandler from "@/commons/mixins/clickHandler";
import get from "lodash/get";

export default {
  name: "Footer",
  mixins: [clickHandler],
  props: {
    links: { type: Array, required: true },
    applicationConfig: { type: Object, required: false }
  },
  data() {
    return {
      socialItems: [
        {
          url: "https://wa.me/393371213792/",
          icon: "whatsapp"
        },
        {
          url: "https://www.linkedin.com/company/s-i-d-al-srl/",
          icon: "linkedin"
        },
        {
          url: "https://www.facebook.com/ZONAProfessionalstore",
          icon: "facebook"
        },
        {
          url: "https://www.instagram.com/zona_professionalstore/",
          icon: "instagram"
        },
        { url: "https://www.youtube.com/@ZONAmarketChannel/", icon: "youtube" }
      ],
      selectedTab: null
    };
  },
  computed: {
    version() {
      return global.config.version;
    },
    logoDesktop() {
      return get(
        this.applicationConfig,
        "metaData.template_model.LOGO_DESKTOP"
      );
    },
    logoMobile() {
      return get(this.applicationConfig, "metaData.template_model.LOGO_MOBILE");
    }
  },
  methods: {
    isSocialLinksColumn(classes) {
      return classes?.search("social") > -1;
    },
    isCordovaPlatform(classes) {
      return this.isSocialLinksColumn(classes) && this.cordovaPlatform;
    },
    isCreditsClass(classes) {
      return classes.search("classes") > -1;
    },
    clickLink: function(event) {
      if (event.target.className.includes("cookie-widget-trigger")) {
        if (window.Cookiebot) {
          window.Cookiebot.show();
        }
      }
    }
  }
};
</script>
